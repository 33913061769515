import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { withRouter } from "react-router-dom";

import JobApplicationSidebarActions from "@ats/src/views/jobApplications/JobApplicationSidebarActions";

import Icon from "@ats/src/components/shared/Icon";
import NavItem from "@ats/src/components/shared/NavItem";
import Tooltip from "@ats/src/components/shared/Tooltip";
import { formatPhoneNumber } from "@shared/lib/utils";
import { useToastContext } from "@shared/context/ToastContext";
import { CopyToClipboard } from "react-copy-to-clipboard";

const createdViaCopy = (createdVia: string) => {
  if (createdVia === "created_via_manual_add") {
    return "Manually added";
  } else if (createdVia === "created_via_bulk_manual_add") {
    return "Imported";
  } else if (createdVia === "created_via_clone") {
    return "Cloned";
  }
  return <span>Applied from job board</span>;
};

function JobApplicationSidebar({
  history,
  match,
  candidate,
  jobApplication,
  jobApplicationsForStage,
  hiringStages,
}) {
  const addToast = useToastContext();

  window.logger("%c[JobApplicationSidebar] ", "background: #EFDDEF; color: #1976D2", {
    history,
    match,
    candidate,
    jobApplication,
    jobApplicationsForStage,
    hiringStages,
  });

  return (
    <>
      <Styled.CandidateProfile>
        <Styled.Header>
          <h1>{candidate.fullName}</h1>
          <div>
            <Styled.Tag>{createdViaCopy(jobApplication.createdVia)}</Styled.Tag>
            <Tooltip label="Copy link to candidate">
              <CopyToClipboard
                text={jobApplication.urls.permalinkUrl}
                onCopy={() => addToast({ title: "Copied candidate link", kind: "success" })}
              >
                <Styled.PermaLink>
                  <Icon name="link" />
                </Styled.PermaLink>
              </CopyToClipboard>
            </Tooltip>
          </div>
        </Styled.Header>

        <Styled.Nav>
          <NavItem to={`${match.url}/overview`} icon="clipboard" label="Overview" chevron />
          <NavItem to={`${match.url}/resume`} icon="file" label="Resume" chevron />
          <NavItem to={`${match.url}/messages`} icon="message-square" label="Messages" chevron />
          <NavItem to={`${match.url}/files`} icon="save" label={`Files`} chevron />
          <NavItem to={`${match.url}/notes`} icon="file-text" label="Private notes" chevron />
        </Styled.Nav>

        <Styled.Divider />

        <Styled.Info>
          <Styled.InfoTitle>Contact information</Styled.InfoTitle>
          {!candidate.phone && !candidate.email && (
            <Styled.InfoRow>
              <Icon name="slash" />
              <span>No email address or phone number</span>
            </Styled.InfoRow>
          )}
          {candidate.phone && (
            <Styled.InfoRow>
              <Icon name="phone" />
              <a href={`tel:${candidate.phone}`}>{formatPhoneNumber(candidate.phone)}</a>
            </Styled.InfoRow>
          )}
          {candidate.email && (
            <Styled.InfoRow>
              <Icon name="mail" />
              <a href={`mailto:${candidate.email}`} target="_blank" rel="noreferrer">
                {candidate.email}
              </a>
              {candidate.hasValidEmail === false && (
                <Tooltip label="This email is not a valid address">
                  <Styled.Invalid>Invalid</Styled.Invalid>
                </Tooltip>
              )}
            </Styled.InfoRow>
          )}

          {candidate.location && (
            <Styled.InfoRow>
              <Icon name="map-pin" />
              <span>{candidate.location}</span>
            </Styled.InfoRow>
          )}
        </Styled.Info>

        {(candidate.linkedinUrl ||
          candidate.githubUrl ||
          candidate.twitterUrl ||
          candidate.dribbbleUrl ||
          candidate.websiteUrl) && (
          <Styled.Info>
            <Styled.InfoTitle>Links</Styled.InfoTitle>
            {candidate.linkedinUrl && (
              <Styled.InfoRow>
                <Icon name="linkedin" />
                <a
                  href={`https://linkedin.com/in/${candidate.linkedinUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`https://linkedin.com/in/${candidate.linkedinUrl}`}
                </a>
              </Styled.InfoRow>
            )}
            {candidate.twitterUrl && (
              <Styled.InfoRow>
                <Icon name="twitter" />
                <a
                  href={`https://twitter.com/${candidate.twitterUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`https://twitter.com/${candidate.twitterUrl}`}
                </a>
              </Styled.InfoRow>
            )}
            {candidate.githubUrl && (
              <Styled.InfoRow>
                <Icon name="git-hub" />
                <a
                  href={`https://github.com/${candidate.githubUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`https://github.com/${candidate.githubUrl}`}
                </a>
              </Styled.InfoRow>
            )}
            {candidate.dribbbleUrl && (
              <Styled.InfoRow>
                <Icon name="dribbble" />
                <a
                  href={`https://dribbble.com/${candidate.dribbbleUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`https://dribbble.com/${candidate.dribbbleUrl}`}
                </a>
              </Styled.InfoRow>
            )}
            {candidate.websiteUrl && (
              <Styled.InfoRow>
                <Icon name="globe" />
                <a href={`https://${candidate.websiteUrl}`} target="_blank" rel="noreferrer">
                  {`https://${candidate.websiteUrl}`}
                </a>
              </Styled.InfoRow>
            )}
          </Styled.Info>
        )}

        {jobApplication.source && (
          <Styled.Info>
            <Styled.InfoTitle>Source</Styled.InfoTitle>
            <Styled.InfoRow>
              <Icon name="eye" />
              <span>{jobApplication.source}</span>
            </Styled.InfoRow>
          </Styled.Info>
        )}

        {/* {jobApplication.ahoyVisit && (
          <Styled.Info>
            {Object.keys(jobApplication.ahoyVisit).map((visitKey, index) => (
              <div key={visitKey}>
                <Styled.InfoTitle>{visitKey}</Styled.InfoTitle>
                <Styled.InfoRow>
                  <span>{jobApplication.ahoyVisit[visitKey] || "N/A"}</span>
                </Styled.InfoRow>
              </div>
            ))}
          </Styled.Info>
        )} */}

        {jobApplication.desiredCompensation && (
          <Styled.Info>
            <Styled.InfoTitle>Desired compensation</Styled.InfoTitle>
            <Styled.InfoRow>
              <Icon name="refresh-cw" />
              <span>{jobApplication.desiredCompensation}</span>
            </Styled.InfoRow>
          </Styled.Info>
        )}
        {/* <Styled.Info>{privateNoteNode}</Styled.Info> */}
      </Styled.CandidateProfile>

      <JobApplicationSidebarActions
        history={history}
        candidate={candidate}
        jobApplication={jobApplication}
        hiringStages={hiringStages}
        jobApplicationsForStage={jobApplicationsForStage}
      />
    </>
  );
}

JobApplicationSidebar.defaultProps = {};

export default withRouter(JobApplicationSidebar);

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.CandidateProfile = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_CandidateProfile;
    ${t.pb(10)}
    flex-shrink: 1;
    overflow-y: auto;
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_Header;
    ${[t.px(4), t.pt(4), t.mb(1)]}
    h1 {
      ${[t.text.h2]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > div {
      ${t.mt(3)}
      display: flex;
      align-items: center;
      margin-bottom: 1.5625rem;
    }
  `;
});

Styled.PermaLink = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_PermaLink;
    ${[t.h(6), t.w(6), t.ml(2), t.rounded.xs]}
    font-size: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};

    &:hover {
      background: ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      cursor: pointer;
    }
  `;
});

Styled.Tag = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_Tag;
    ${[t.h(6), t.px(2), t.text.xs, t.rounded.xs]}
    display: inline-flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    line-height: 1.4;
    overflow: hidden;
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `;
});

Styled.Nav = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_Nav;
  `;
});

Styled.Divider = styled.hr((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_Divider;
    ${[t.mb(0)]}
    margin-top: 2.25rem;
    width: 100%;
    border: none;
  `;
});

Styled.Info = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_Info;
    ${[t.mt(3), t.px(4)]}
    flex-shrink: 1;
  `;
});

Styled.InfoTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_InfoTitle;
    ${[t.text.xs, t.text.bold, t.h(6)]}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    display: flex;
    align-items: center;
  `;
});

Styled.InfoRow = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_InfoRow;
    ${[t.text.xs, t.h(6)]}
    display: flex;
    align-items: center;
    svg {
      ${t.mr(2)}
      flex-shrink: 0;
    }
    span,
    a,
    button {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.4;
    }
    a:hover,
    button:hover {
      text-decoration: underline;
    }
  `;
});

Styled.Invalid = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationSidebar_InfoTitle;
    ${[t.h(4), t.ml(1), t.mb("px"), t.rounded.xs]};
    font-size: 0.625rem;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    background-color: ${t.dark ? t.color.gray[900] : t.color.red[100]};
    border: 1px solid ${t.dark ? t.color.red[600] : t.color.red[600]};
    color: ${t.dark ? t.color.red[500] : t.color.red[600]};
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    line-height: 1;
    overflow: visible;
    align-items: center;
    cursor: default;
  `;
});
